import { Suspense } from 'react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import history from '@/utils/router'
import { renderRoutes } from '@/config/route/common'
import { browser } from '@/utils/tools/browser'
import { StaticRouter } from 'react-router-dom/server'

const App = (props: { pageData: any; routes: any; location?: string }) => {
  const { pageData, routes, location } = props

  return (
    <Suspense fallback={<></>}>
      {browser ? (
        <HistoryRouter history={history as any}>{renderRoutes({ routes, pageData })}</HistoryRouter>
      ) : (
        <StaticRouter location={location}>{renderRoutes({ routes, pageData })}</StaticRouter>
      )}
    </Suspense>
  )
}

export default App
