import withHydrateApp from '@/pages/withHydrateApp'
import { Route, Routes } from 'react-router-dom'

const renderRoutes = (opt: { routes: any; pageData: any }) => {
  function transformRoutes(data: any) {
    return data.map((route: any, index: number) => {
      route.element.displayName = route.name
      const Com = withHydrateApp(route.element)

      const pageProps = opt.pageData?.[route.name] ?? {}

      return (
        <Route key={index} path={route.path} element={<Com {...{ pageProps }} />}>
          {route.children && route.children.length ? transformRoutes(route?.children) : <></>}
        </Route>
      )
    })
  }

  return <Routes>{transformRoutes(opt.routes)}</Routes>
}

export { renderRoutes }
