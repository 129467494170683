import { lazy } from 'react'

const Layout = lazy(() => import('@/layouts/BaseLayout'))
const Home = lazy(() => import('@/pages/home'))
const PageNotFound = lazy(() => import('@/pages/404'))

/**
 * @property {string}name 路由名称，确保和页面的文件夹名称一致 pages/home/index.tsx => name:"home"
 */
const routes: any = [
  {
    path: '',
    name: 'layout',
    element: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        children: [],
        element: Home
      }
    ]
  },

  {
    path: '*',
    name: '404',
    children: [],
    element: PageNotFound
  }
]

export { routes }
