import { ComponentType, useEffect, useLayoutEffect, useState } from 'react'

const isObj = (obj: any) => Object.prototype.toString.call(obj) === '[object Object]'
export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect
export type IGetServerSideData<R = any> = () => Promise<R>
let loadFlag = false
function wrapper(Component: any) {
  return (props: { pageProps: any }) => {
    const [data, setData] = useState(!loadFlag ? props.pageProps : {})
    useBrowserLayoutEffect(() => {
      async function fetch() {
        if (!isObj(data) || !Object.keys(data).length) {
          const com =
            typeof Component._payload._result === 'function'
              ? (await Component._payload._result()).default
              : Component._payload._result.default
          if ('getServerSideData' in com) {
            const data = await com.getServerSideData()
            data && setData(data)
          }
        } else {
          loadFlag = true
        }
      }
      fetch()
    }, [])
    return <Component {...data} />
  }
}
const withHydrateApp = (Component: any) => {
  const $Component = wrapper(Component) as ComponentType<{ pageProps: any }>
  return $Component
}
export default withHydrateApp
