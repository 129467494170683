import { browser } from "../tools/browser";
import {
  createMemoryHistory,
  createBrowserHistory,
  MemoryHistoryOptions,
  History as HistoryType,
  Location,
} from "history";
export type HistoryProps<
  Q extends Record<string, any> = {},
  P extends Record<string, any> = {}
> = typeof History.history & { location: Location & { query: Q; state: P } };
type Navigate<
  Q extends Record<string, any> = {},
  P extends Record<string, any> = {}
> = {
  pathname: string;
  query: Q;
  params: P;
};
class History {
  static historyInstance: HistoryType;
  static initHistory(opt?: MemoryHistoryOptions) {
    if (!browser) {
      this.historyInstance = createMemoryHistory(opt);
    } else {
      if (!this.historyInstance) {
        this.historyInstance = createBrowserHistory();
      }
    }
  }
  private static push(
    to: string | Partial<Navigate>,
    type: "replace" | "push"
  ) {
    const p = {};
    const state = typeof to === "object" ? to.params ?? {} : {};
    if (typeof to === "string") {
      Object.assign(p, { pathname: to });
    } else {
      Object.assign(p, { pathname: to.pathname });
      if (to.query && Object.keys(to.query).length > 0) {
        const searchParams = Object.entries(to.query).reduce(
          (prev, [key, value]) => {
            prev.append(
              key,
              typeof value === "object" ? JSON.stringify(value) : (value as any)
            );
            return prev;
          },
          new URLSearchParams()
        );
        Object.assign(p, { search: searchParams.toString() });
      }
    }
    this.historyInstance[type](p, state);
  }
  public static transformLocation(location: Location) {
    return {
      ...location,
      query: Array.from(new URLSearchParams(location.search).entries()).reduce(
        (prev, [key, value]) => {
          Object.assign(prev, { [key]: value });
          return prev;
        },
        {}
      ),
    };
  }

  public static get history() {
    if (browser) {
      if (!this.historyInstance) {
        this.historyInstance = createBrowserHistory();
      }
    }
    const { location, ...rest } = this.historyInstance;
    return {
      ...rest,
      location: this.transformLocation(location),
      push: (to: string | Partial<Navigate>) => this.push(to, "push"),
      replace: (to: string | Partial<Navigate>) => this.push(to, "replace"),
    };
  }
}

export const history = new Proxy(
  {},
  {
    get: (_, p) => {
      return Reflect.get(History.history, p);
    },
  }
) as typeof History.history;

export default History;
