import ReactDOM from 'react-dom/client'
import { routes } from './config/route/clientRoutes'
import App from './pages/App'
import './index.scss'

async function render() {
  let pageData = {}
  const text = document.getElementById('__INITIAL_DATA')?.textContent
  if (text) {
    try {
      const data = JSON.parse(text)
      pageData = data.initialData
    } catch {}
  }
  ReactDOM.hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <App pageData={pageData} routes={routes} />
  )
}
render()
